import { useEffect, lazy } from 'react';
import {
  createBrowserRouter,
  useNavigate,
  useRouteError,
  Navigate,
} from 'react-router-dom';
import LazyComponent from '@/components/common/LazyComponent';
import LazyComponentCloud from '@/components/common/LazyComponentCloud';

const NotAuth = lazy(() => import('@/views/common/NotAuth'));
const NotFind = lazy(() => import('@/views/common/NotFind'));

// 微信小程序
const App = lazy(() => import('@/views/App'));
const Login = lazy(() => import('@/views/Client/Login'));
const HomePage = lazy(() => import('@/views/Client/HomePage'));
const Banner = lazy(() => import('@/views/Client/Banner'));
const TopicalSubject = lazy(() => import('@/views/Client/TopicalSubject'));
const Recommend = lazy(() => import('@/views/Client/Recommend'));
const MaterialReview = lazy(() => import('@/views/Client/MaterialReview'));
const CompilationReview = lazy(
  () => import('@/views/Client/CompilationReview'),
);
const Album = lazy(() => import('@/views/Client/Album'));
const Plan = lazy(() => import('@/views/Client/Plan'));
const User = lazy(() => import('@/views/Client/User'));
const Role = lazy(() => import('@/views/Client/Role'));
const Authority = lazy(() => import('@/views/Client/Authority'));
const ActivationCode = lazy(() => import('@/views/Client/ActivationCode'));
const Vip = lazy(() => import('@/views/Client/Vip'));
const weappUser = lazy(() => import('@/views/Client/weappUser'));
const BasicConfig = lazy(() => import('@/views/Client/BasicConfig'));
const WeappCloudSourceMaterial = lazy(
  () => import('@/views/Client/SourceMaterial'),
);
const WeappCloudCollection = lazy(() => import('@/views/Client/Collection'));
const WeappCloudAlbum = lazy(() => import('@/views/Client/WeppAlbum'));
const SaleList = lazy(() => import('@/views/Client/SaleList'));
const SaleDetails = lazy(() => import('@/views/Client/SaleDetails'));

// 微信云盘
const CloudApp = lazy(() => import('@/views/WeappCloud/CloudApp'));
const CloudHome = lazy(() => import('@/views/WeappCloud/CloudHome'));
const CloudLogin = lazy(() => import('@/views/WeappCloud/CloudLogin'));
const SourceMaterial = lazy(() => import('@/views/WeappCloud/SourceMaterial'));
const Collection = lazy(() => import('@/views/WeappCloud/Collection'));
const CloudAlbum = lazy(() => import('@/views/WeappCloud/Album'));

const ErrorBoundary = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    error.status === 404 && navigate('/404', { replace: true });
  }, []);
  return null;
};

const router = [
  {
    path: '/',
    element: <Navigate to="/weapp/home-page" />,
  },
  // 微信小程序管理端
  {
    path: '/weapp',
    element: <LazyComponent LazyChildren={App} empower={false} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'home-page',
        element: <LazyComponent LazyChildren={HomePage} empower={false} />,
      },
      // banner 管理
      {
        path: 'banner',
        element: <LazyComponent LazyChildren={Banner} />,
      },
      // 主题管理
      {
        path: 'topical-subject',
        element: <LazyComponent LazyChildren={TopicalSubject} />,
      },
      // 分类推荐管理
      {
        path: 'recommend',
        element: <LazyComponent LazyChildren={Recommend} />,
      },
      // 素材审核
      {
        path: 'material-review',
        element: <LazyComponent LazyChildren={MaterialReview} />,
      },
      // 合辑审核
      {
        path: 'compilation-review',
        element: <LazyComponent LazyChildren={CompilationReview} />,
      },
      // 专辑管理
      {
        path: 'album',
        element: <LazyComponent LazyChildren={Album} empower={false} />,
      },
      // 计划管理
      {
        path: 'plan',
        element: <LazyComponent LazyChildren={Plan} />,
      },
      // 小程序用户管理
      {
        path: 'weapp-user',
        element: <LazyComponent LazyChildren={weappUser} />,
      },
      // 系统用户管理
      {
        path: 'user',
        element: <LazyComponent LazyChildren={User} />,
      },
      // 角色管理
      {
        path: 'role',
        element: <LazyComponent LazyChildren={Role} />,
      },
      // 权限设置
      {
        path: 'authority',
        element: <LazyComponent LazyChildren={Authority} />,
      },
      // 兑换码
      {
        path: 'activationCode',
        element: <LazyComponent LazyChildren={ActivationCode} />,
      },
      // VIP 价格
      {
        path: 'vip',
        element: <LazyComponent LazyChildren={Vip} />,
      },
      {
        path: 'sale-list',
        element: <LazyComponent LazyChildren={SaleList} />,
      },
      {
        path: 'sale-details',
        element: <LazyComponent LazyChildren={SaleDetails} empower={false} />,
      },
      // 基础设置
      {
        path: 'basic-config',
        element: <LazyComponent LazyChildren={BasicConfig} />,
      },
      // 素材
      {
        path: 'cloud-source-material',
        element: <LazyComponentCloud LazyChildren={WeappCloudSourceMaterial} />,
      },
      // 合辑
      {
        path: 'cloud-collection',
        element: <LazyComponentCloud LazyChildren={WeappCloudCollection} />,
      },
      // 专辑
      {
        path: 'cloud-album',
        element: <LazyComponentCloud LazyChildren={WeappCloudAlbum} />,
      },
    ],
  },
  {
    path: '/login',
    element: (
      <LazyComponent LazyChildren={Login} verify={false} empower={false} />
    ),
  },
  // 微信小程序云盘管理端
  {
    path: '/weapp-cloud',
    element: <LazyComponentCloud LazyChildren={CloudApp} />,
    errorElement: <ErrorBoundary />,
    children: [
      // 欢迎页面
      {
        path: 'home-page',
        element: <LazyComponentCloud LazyChildren={CloudHome} />,
      },
      // 素材
      {
        path: 'source-material',
        element: <LazyComponentCloud LazyChildren={SourceMaterial} />,
      },
      // 合辑
      {
        path: 'collection',
        element: <LazyComponentCloud LazyChildren={Collection} />,
      },
      // 专辑
      {
        path: 'album',
        element: <LazyComponentCloud LazyChildren={CloudAlbum} />,
      },
    ],
  },
  // 微信云盘登录
  {
    path: '/weapp-cloud-login',
    element: <LazyComponentCloud LazyChildren={CloudLogin} verify={false} />,
  },
  {
    path: '/403',
    element: (
      <LazyComponent LazyChildren={NotAuth} verify={false} empower={false} />
    ),
  },
  {
    path: '/404',
    element: (
      <LazyComponent LazyChildren={NotFind} verify={false} empower={false} />
    ),
  },
];

export default createBrowserRouter(router, {
  basename: '/',
});
