const {
  REACT_APP_BASE_URL,
} = process.env;

export const host = REACT_APP_BASE_URL;

// 小程序支持播放的视频格式
export const acceptVideoExt = ['.mp4', '.3gp', '.m3u8', '.webm'];

// 小程序支持播放的音频格式
export const acceptAudioExt = ['.mp3', '.aac', '.m4a', '.wav', '.mpga'];

// 小程序支持显示的图片格式
export const acceptImageExt = ['.jpg', '.jpeg', '.png', '.webp'];

// 小程序支持显示的pdf格式
export const acceptPdfExt = ['.pdf'];

// mime 与 ext 对照表
export const mimeExt: { [key: string]: string[] } = {
  video: acceptVideoExt,
  audio: acceptAudioExt,
  image: acceptImageExt,
  pdf: acceptPdfExt,
};

// 文件上传地址
export const uploadFileUrl = `${REACT_APP_BASE_URL}/api/webmanager/file/uploadFile`;
