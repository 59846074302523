import { useRef, useEffect, ReactNode } from 'react';
import {
  Modal,
} from 'antd';
import { decode, encode } from 'js-base64';
import { nanoid } from 'nanoid';
import {
  mimeExt,
} from '@/config/staticData';

// 请求根目录
export const baseURL = process.env.REACT_APP_BASE_URL || '';

// 第一次useEffect函数不触发
export const useDidUpdateEffect = (fn: Function, inputs?: any[]) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    didMountRef.current ? (fn && fn()) : (didMountRef.current = true);
  }, inputs);
};

// 获取 token
export const getToken = () => {
  const Authorization = window.localStorage.getItem('Authorization');
  return Authorization ? decode(Authorization) : '';
};

// 获取用户信息
// 用户信息
// superAdmin: 超级管理员 YES-是, NO 否
// name: 姓名
// account: 账号
// roles：角色信息 roleCode-角色编码，roleName-角色名称
// diskName：云盘名称
export const getUserInfo = () => {
  const userInof = window.localStorage.getItem('userInof');
  return userInof ? JSON.parse(decode(userInof)) : undefined;
};

// 获取用户权限
// authFunction: 权限标识
// name：权限名称
export const getAuth = () => {
  const Permissive = window.localStorage.getItem('Permissive');

  return !!Permissive ? JSON.parse(decode(Permissive)) : undefined;
};

// 获取网盘 token
export const getCloudToken = () => {
  const AuthorizationCloud = window.localStorage.getItem('AuthorizationCloud');
  return AuthorizationCloud ? decode(AuthorizationCloud) : '';
};

// 获取网盘用户信息
export const getCloudUserInfo = () => {
  const userInofCloud = window.localStorage.getItem('userInofCloud');
  return userInofCloud ? JSON.parse(decode(userInofCloud)) : undefined;
};

// 通过文件id拼装全路径地址
export const getFileUrlById = (id: string) => `${baseURL}/service-file/file/file-view?id=${id}`;

export const deepQueryTreeByChild = (tree: any[], value: string, key: string) => {
  let stark: any[] = [];

  stark = stark.concat(tree);

  while (stark.length) {
    const temp = stark.shift();
    if (temp.children) {
      stark = temp.children.concat(stark);

      if (temp.children.find((item: any) => item[key] === value)) return temp;
    }
  }
};

// 格式化TreeData
export const initTreeData = (arr: any[], title = 'name', key = 'id', children = 'child'): any[] => {
  return arr.map((a: any) => ({
    ...a,
    title: a[title],
    key: a[key],
    children: a[children] ? initTreeData(a[children]) : [],
  }));
};

/**
 * 获得TreeData中的选中的数据
 * @param data: 被查询的数据
 * @param key: 判断是否选中的键名
 * @param child：存在子级时的键名
 * @param id：被选中时，需要选中的键名
 * @returns：被选中键名的数组
 */
export const getSelectedKeys = (data: any[] = [], key = 'isSelect', child = 'child', id = 'id') => {
  const keyArray: number[] = [];

  const loop = (list: any[]) => {
    list.forEach((item: any) => {
      if (item[key]) {
        keyArray.push(item[id]);
      }

      if (!!item[child]?.length) {
        loop(item[child]);
      }
    });
  };

  loop(data);
  return keyArray;
};

// 通过文件后缀查文件类型
export const getFileTypeByExt = (ext: string) => {
  return Object.keys(mimeExt).find((item) => mimeExt[item].includes(ext.toLowerCase()));
};

/**
 * reorderListByIndex: 将list中的2个index元素交换伪装
 * @param list：被操作的数组
 * @param startIndex：交换元素的index
 * @param endIndex: 被交换元素的index
 */

export const reorderListByIndex = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * 根据 url 返回一个and中Upload使用的File对象
 */
export const getUploadFileByUrl = (url: string, name?: string) => {
  if (!url) return undefined;

  const fileName = url.split('/').pop();

  return {
    name: name || fileName,
    url,
    percent: 100,
    status: 'done',
    thumbUrl: url,
    uid: nanoid(),
  };
};

/**
 * 删除弹窗
 */
interface DeleteConfirmModalType {
  content?: string | ReactNode,
  title?: string,
  onOk: () => any,
}
export const deleteConfirmModal = (props: DeleteConfirmModalType) => {
  const { content = '确认删除数据？删除后数据将不可恢复。', title = '警告', onOk } = props;
  const modal = Modal.confirm({
    title,
    content,
    onOk,
  });

  modal.update((prevConfig) => ({
    ...prevConfig,
    onCancel: () => modal.destroy(),
  }));
};

type ConfirmModalProps = {
  title?: string | ReactNode,
  width?: number,
  children?: string | ReactNode,
  onCancel?: Function,
  onConfirm?: Function,
}

export const ConfirmModal = ({
  title = '提示',
  width = 360,
  children,
  onCancel,
  onConfirm,
}: ConfirmModalProps) => {
  return (
    <Modal
      open
      width={width}
      title={title}
      onCancel={() => onCancel && onCancel()}
      okButtonProps={{
        onClick: () => onConfirm && onConfirm(),
      }}
    >
      {children}
    </Modal>
  );
};

export const LinkToCloud = (url: string) => {
  // const token = getCloudToken();

  // if (!token) {
  const info = getUserInfo();

  const userInof = {
    forSystemEnum: 'WEB',
    diskName: info.diskName,
    account: info.account,
  };

  window.localStorage.setItem('AuthorizationCloud', encode(getToken()));
  window.localStorage.setItem('userInofCloud', encode(JSON.stringify(userInof)));
  // }

  window.open(url, '_blank');
};

/**
 * 下载文件
 * @param res：二进制数据
 * @param fileName：名称
 * @param type：文件类型
 */
export const downloadFileAct = (res: any, fileName: string, type?: string) => {
  const blob = new Blob([res], {
    type,
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

// 返回文件名称
export const getFileName = (value: string) => {
  const index = value.lastIndexOf('.');
  return index ? value.slice(0, index) : undefined;
};

export const CloudTitle = '牛哇哇网盘';
export const WeappTitle = '牛哇哇管理后台';
