import {
  Suspense,
  LazyExoticComponent,
  ReactElement,
} from 'react';
import {
  Navigate,
} from 'react-router-dom';
import {
  message,
} from 'antd';
import GlobalLoading from './GlobalLoading';
import {
  getCloudToken,
} from '@/utils';

type IProps = {
  LazyChildren: LazyExoticComponent<() => ReactElement> | any,
  verify?: boolean,
}

/**
 * 路由带校验功能
 * @param verify：校验是否登录
 * @returns
 */
const LazyImportComponent = ({ LazyChildren, verify = true }: IProps) => {
  const token = getCloudToken();

  // 需要校验登录
  if (verify && !token) {
    message.warning('登录失效，请重新登录');
    return <Navigate to="/weapp-cloud-login" replace />;
  }

  return (
    <Suspense fallback={<GlobalLoading />}>
      <LazyChildren />
    </Suspense>
  );
};

export default LazyImportComponent;
