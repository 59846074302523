import {
  Suspense,
  LazyExoticComponent,
  ReactElement,
  useMemo,
} from 'react';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import {
  message,
} from 'antd';
import GlobalLoading from './GlobalLoading';
import {
  getToken,
  getUserInfo,
  getAuth,
} from '@/utils';

type IProps = {
  LazyChildren: LazyExoticComponent<() => ReactElement> | any,
  verify?: boolean,
  empower?: boolean,
}

/**
 * 路由带校验功能
 * @param verify：校验是否登录
 * @param empower：校验是否授权
 * @returns
 */
const LazyImportComponent = ({ LazyChildren, verify = true, empower = true }: IProps) => {
  const { pathname } = useLocation();
  const token = getToken();

  // 已登录后校验授权。分为2种情况： 需要校验和不需要校验
  const hasAuthed = useMemo(() => {
    if (!empower) return true;

    const userInfo = getUserInfo();
    const auth = getAuth();

    // 超级管理员
    if (userInfo?.superAdmin === 'YES') {
      return true;
    }

    return auth?.map((item: any) => item.authFunction).includes(pathname);
  }, [pathname]);

  // 需要校验登录
  if (verify && !token) {
    message.warning('登录失效，请重新登录');
    return <Navigate to="/login" replace />;
  }

  return hasAuthed ? (
    <Suspense fallback={<GlobalLoading />}>
      <LazyChildren />
    </Suspense>
  ) : <Navigate to="/403" replace />;
};

export default LazyImportComponent;
